<!--footer-->
<footer class="footer">
  <!--foottop-->
  <div class="foot-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-6">
          <h2 class="bdr" i18n="About Company">About Company</h2>
          <p>
            {{"Als traditioneller Handwerksbetrieb, der in dritter Generation von Metzgermeister Dirk Hanten geführt wird, ist eine gleichbleibende, geprüfte Qualität für uns selbstverständlich. Gleichzeitig sind wir aber auch Neuem gegenüber aufgeschlossen und verstehen uns als innovativer Ideengeber und kompetenter Partner für alle Fragen rund um die Bereiche Catering, Party- und Eventlösungen."|translate}}
          </p>
        </div>
        <div class="col-lg-6 col-xl-6">
          <div class="row">
            <div class="col-12">
              <div class="foot-payment-logo">
                &nbsp;
                <!-- <ul>
                  <li>
                    <img
                      src="../../../assets/images/visa.jpg"
                      alt="payment"
                      i18n-alt
                    />
                  </li>
                  <li>
                    <img
                      src="../../../assets/images/mastercard.jpg"
                      alt="mastercard"
                      i18n-alt
                    />
                  </li>
                  <li>
                    <img
                      src="../../../assets/images/paypal.jpg"
                      alt="paypal"
                      i18n-alt
                    />
                  </li>
                  <li>
                    <img
                      src="../../../assets/images/discover.jpg"
                      alt="discover"
                      i18n-alt
                    />
                  </li>
                  <li>
                    <img
                      src="../../../assets/images/amazon.jpg"
                      alt="amazon"
                      i18n-alt
                    />
                  </li>
                  <li>
                    <img
                      src="../../../assets/images/american-express.jpg"
                      alt="american-express"
                      i18n-alt
                    />
                  </li>
                </ul> -->
              </div>
            </div>
            <div class="col-md-6">
              <div class="foot-link">
                <span class="link-title" i18n="Quick Links">Quick Links</span>
                <ul>
                  <li>
                    <a
                      href="javascript: void(0);"
                      (click)="goToPage(aboutUs)"
                      i18n="About us"
                      >About us</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript: void(0);"
                      (click)="gotoContactUs()"
                      i18n="Contacat Us"
                      >Contacat Us</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript: void(0);"
                      (click)="goToRedirectsericeInfo()"
                      >{{ "Services Information" | translate }}</a
                    >
                  </li>
                  <!--   <li><a href="javascript: void(0);" i18n="Sign up">Sign up</a></li> -->
                  <!--   <li><a href="javascript: void(0);" i18n="Suggest a Restaurant">Suggest a Restaurant</a></li> -->
                  <!-- <li><a href="javascript: void(0);" (click)="goToPage(helpSupport)"  i18n="Help">Help</a></li>
                  <li><a href="javascript: void(0);" i18n="Privacy Policy/T&Cs">Privacy Policy/T&Cs</a></li> -->
                  <!--   <li><a href="javascript: void(0);" i18n="My account">My account</a></li> -->
                </ul>
              </div>
            </div>
            <!--  <div class="col-md-3">
              <div class="foot-link">
                <span class="link-title" i18n="A Bit More About Us">A Bit More About Us</span>
                <ul> -->
            <!-- <li><a href="javascript: void(0);" i18n="Restaurant Sign up">Restaurant Sign up</a></li>
                  <li><a href="javascript: void(0);" i18n="How do we use cookies ?">How do we use cookies ?</a></li>  -->
            <!--    <li><a href="javascript: void(0);" (click)="goToPage(aboutUs)"  i18n="About us">About us</a></li> -->
            <!-- <li><a (click)="goToPage(ourServicesPath)" title="Our Services" href="javascript: void(0)" >Our Services</a></li>
                  <li><a (click)="goToPage(faqPage)"  title="FAQ’s" href="javascript: void(0)" >FAQ’s</a></li> -->

            <!-- <li><a href="javascript: void(0);" i18n="Company website">Company website</a></li> -->
            <!--  </ul>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="foot-link">
                <span class="link-title" i18n="Store Information"
                  >Store Information</span
                >
                <p>
                  <i class="fas fa-map-marker-alt"></i
                  ><ng-container i18n="address">address</ng-container>:
                  {{ companyDetails.address }}
                </p>
                <p (click)="mailMe()">
                  <i class="fas fa-envelope"></i>
                  <ng-container i18n="email">email</ng-container>:
                  {{ companyDetails.email }}
                </p>
                <p (click)="teleNoOpen()">
                  <i class="fas fa-phone-alt"></i>
                  <ng-container i18n="phone">phone</ng-container>:
                  {{ companyDetails.phone }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--foottop-->
  <!--footbottom-->
  <div class="foot-bottom">
    <div class="container">
      <div class="row">
        <div class="col-sm-7">
          <p>
            &#169;
            {{currentYear}} {{companyDetails.name}}
           {{"All Rights Reserved."|translate}}
          </p>
        </div>
        <div class="col-sm-5">
          <ul class="social-icon">
            <li>
              <a  href="https://www.facebook.com/hantenevent" target="_blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
         <!--    <li>
              <a href="javascript: void(0);"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
              <a href="javascript: void(0);"><i class="fab fa-dribbble"></i></a>
            </li>
            <li>
              <a href="javascript: void(0);"><i class="fab fa-vimeo"></i></a>
            </li> -->
            <li>
              <a 
               href="javascript: void(0);" href="https://www.instagram.com/vorbestell_app/" target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--footbottom-->
</footer>
<!--footer-->
