import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';//'../services/session-storage.service';
import { LocalStorageService } from '../services/local-storage.service';
import { environment } from '../../../environments/environment';
import { version } from '../../../../package.json';
import {Params} from '../constants/params';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private sessionStorage: SessionStorageService, private localStorageService: LocalStorageService) { }
	private setBasicHeaders(headers: HttpHeaders) {
		const token = this.localStorageService.authToken; // Figure out, from where to take token
		headers.set('token', token);
		headers.append(Params.tokenKey, environment.TOKENKEY);
		headers.append(Params.tokenIv, environment.TOKENIV);
		headers.append(Params.version_code, version);
		headers.append(Params.devicetype, environment.devicetype);
		headers.append('content-type', 'Application/Json');
	}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		//let googleApi = environment.services.googleMapApi.baseUrl;
		// if (this.localStorageService.authToken && (!request.url.includes("upload_images") || !request.url.includes("upload_images/remove_defaulter_doc"))) {
		let header:any={}
		if (this.localStorageService.authToken) {
		
			 header['token']=this.localStorageService.authToken;
			 header[Params.tokenKey]=environment.TOKENKEY;
			 header[Params.tokenIv]=environment.TOKENIV;
		/* 	 header[Params.version_code]=version; */
			 header[Params.devicetype]=environment.devicetype;
			
		}else{
			header[Params.tokenKey]=environment.TOKENKEY;
			header[Params.tokenIv]=environment.TOKENIV;
		/* 	header[Params.version_code]=version; */
			header[Params.devicetype]=environment.devicetype;
		}
		request = request.clone({
			setHeaders: header
		});
		return next.handle(request);
	}

	// private getAuthToken = () => {
	//     let isAlive: boolean = true;
	//     return this.sessionStorage.userAuthDetails.takeWhile(() => isAlive).subscribe((data) => {
	//         return data.result.authToken;
	//     });
	// };
}
