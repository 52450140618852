export class Params {
    static userId = 'userId';
    static userEmail = 'useremail';
    static popperJs = 'popperJs';
    static owlCarouselMinJs = 'owlCarouselMinJs';
    static mobileMenuFilterJs = 'mobileMenuFilterJs';
    static mobileMenuJs = 'mobileMenuJs';
    static customJs = 'customJs';
    static tokenKey ='TOKENKEY';
    static tokenIv ='TOKENIV';
    static version_code ='Version_code';
    static devicetype ='devicetype';
    static company_detail_session='company_detail';
    static accessToken='accessToken';
    static user_details='user_details';
    static selectedBranchId='selectedBranchId';
    static selectedBrDt= "selectedBrDt";
    static companyDt = "companyDt"
    static defaultCatImage="../../../assets/images/product-icon.png";
    static defaultSubCatImage="../../../assets/images/no-image-cat.jpg";
    static prevCircleArrow="../../../assets/images/prev-circle-arrow.png";
    static nextCircleArrow="../../../assets/images/next-circle-arrow.png";
    static productPlaceholderImg="../../../assets/images/no-image-cat.jpg";
    static device_id='device_id';
    static device_type='device_type';
    static actionAdd='Add';
    static actionEdit='Edit';
    static actionUpdate='Update';
    static actionDelete='Delete';
    static actionView='View';
    static noImageCat='../../../assets/images/no-image-cat.jpg';
    static noCatImageToReplace="no_image.png";
    static shppingCartSession="shppingCartSession";
    static addQtyAction='add';
    static subQtyAction='sub';
    static  qtyToDesc=10;
    static qtyToInc=10;
    static minProdQtyInGram=50;
    static maxProdQtyInGram=100000;
    static calculation_typeAdd="1";
    static calculation_typeMultiPly="2";
    static  priceCalculateionTypes='unit';
    static minQtyInUnit=1;
  }
