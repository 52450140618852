import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../shared/services/router.service';
import { Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  currentUrl: string;
  constructor(private routerService: RouterService, private router: Router, private authService: AuthService) { }
  ngOnInit(): void {
    this.currentUrl = this.router.url;
  }
  redirectViewProfile() {
    this.routerService.redirectMyAccount();
  }
  redirectToMyAccountDashboard() {
    this.routerService.redirectToMyAccountDashboard();
  }
  redirectEditProfile() {
    this.routerService.redirectMyAccountEdit();
  }
  redirectChangePassword() {
    this.routerService.redirectChangePassword();
  }
  goToMyOrders(){
    this.routerService.redirectTomyOrders();
  }
  redirectToMyAddresses() {
    this.routerService.redirectToMyAddresses();
  }
  logout() {
    this.authService.logout();
  }
}
