import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { take, map, filter } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { Params } from '../constants/params';
export interface Product {
  id: string;
  item_wish:string;
  is_item_wish:boolean
  category_id: string;
  is_unit: string;
  branch: any;
  /*"company_id":string,
    "category_id":string,
    "name":string, */
  qtySelected: any;
  attr: any;
  qty: any;
  unique_cart_id: any;
  is_attribute: boolean;
  "price": number;
  "apply_image": string;
  "imageUrl": string;
  "name": string;

  /* "description":string,
    "allergy_info":string,
    "price":string,
    "is_unit":string,
    "min_limit":string,
    "max_limit":string,
    "is_attribute":string,
    "apply_image":string,
    "image":string,
    "priority":string,
    "is_item_wish":string,
    "status":"1",
    "unit_quantity":string,
    "imageUrl":string,
    "quantities":Array<any>,
    "attribute":Array<any>,
    "toppings":any,
    "branch":any,
    "options":any */
}
@Injectable({
  providedIn: 'root',
})
export class CartService {
  addQtyAction = Params.addQtyAction;
  subQtyAction = Params.subQtyAction;

  constructor(private localStorageService: LocalStorageService) {
    let existingCartItems: any = this.localStorageService.shppingCartSession;
    if (existingCartItems == '') {
      existingCartItems = [];
    }
    this.itemsSubject.next(existingCartItems);
  }

  private itemsSubject = new BehaviorSubject<Product[]>([]);
  items$ = this.itemsSubject.asObservable();
  addToCart(product: Product) {
    this.items$
      .pipe(
        take(1),
        map((products: any) => {
          const productExistInCart = products.filter(
            (prod) => prod.unique_cart_id == product.unique_cart_id
          );

          if (productExistInCart.length == 0) {
            products.push(product);
            this.localStorageService.shppingCartSession = products;
          } else {
            let _newQty = parseInt(product.qty);
            this.mangeQty(product, this.addQtyAction, _newQty);
          }

        })
      )
      .subscribe();
  }
  calcuatePriceEachGram(price: any, qty: any) {
    return ((parseFloat(price.toString().replace(/,/g, '')) * parseInt(qty)) / 1000);
  }
  calcuateUnitPrice(price: any, qty: any) {
    let prod_price = parseFloat(price.toString().replace(/,/g, '')); //price per kg
    let prod_qty = parseInt(qty);
    return prod_price * prod_qty;
  }
  calculatePrice(type, price, qty, attr) {
    let priceToCalc: any = 0.00;
    let _qty=parseInt(qty);
    if (type == Params.priceCalculateionTypes) {
     
      priceToCalc = this.calcuateUnitPrice(price, _qty)
    } else {
      priceToCalc = this.calcuatePriceEachGram(price, _qty)
    }
    if (attr.length > 0) {
      let attributes = attr
      for (let a = 0; a < attributes.length; a++) {
        let attrs = attributes[a];
        let attrPrice = parseFloat(attrs["price"].toString().replace(/,/g, ''))
        if (attrs["calculation_type"] == Params.calculation_typeAdd) {
          priceToCalc = (priceToCalc + attrPrice);
        } else if (attrs["calculation_type"] == Params.calculation_typeMultiPly) {
          priceToCalc = priceToCalc + (attrPrice * _qty);
        }
      }
    }
    return priceToCalc;
  }
  updateQty(flag = this.addQtyAction, newQty, existingQty) {
    let updatedQty = 0;
    if (flag == this.addQtyAction) {
      updatedQty = <number>parseInt(newQty + existingQty);
    } else if (flag == this.subQtyAction) {
      updatedQty = <number>existingQty - newQty;
      if (updatedQty < 0) {
        updatedQty = 0;
      } else if (updatedQty == 0) {
        updatedQty = 1;
      }
    }
    return updatedQty;
  }
  mangeQty(item: Product, action: string = this.addQtyAction, newQty) {
    this.items$
      .pipe(
        take(1),
        map((products: any) => {
          const productExistInCart = products.find(
            (prod) => prod.id == item.id
          );
          if (productExistInCart) {
            for (let i = 0; i < products.length; i++) {
              let existingItm = products[i];
              let existingQty = parseInt(existingItm.qty);
              let _action = action;
              let _newQty = parseInt(newQty);
              if (existingItm.unique_cart_id == item.unique_cart_id) {
                let updatedQty = this.updateQty(
                  _action,
                  _newQty,
                  existingQty
                );
                existingItm['qty'] = updatedQty;
                this.localStorageService.shppingCartSession = products;
              }
            }
          }
        })
      )
      .subscribe();
  }
  checkForIdenticalProduct(comingItemAttrib, existingItemAttribe) {
    let isSameProd: boolean = false;
    let totalSimilarItems = 0;
    for (let ex = 0; ex < existingItemAttribe.length; ex++) {
      for (let cia = 0; cia < comingItemAttrib.length; cia++) {
        if (existingItemAttribe[ex]['id'] === comingItemAttrib[cia]['id']) {
          totalSimilarItems++
        }
      }
    }
    if (totalSimilarItems == existingItemAttribe.length) {
      isSameProd = true
    }
    return isSameProd;
  }
  removeItemFromCart(unique_cart_id) {
    this.items$
      .pipe(
        take(1),
        map((products: any) => {
          const productExistInCart = products.filter(
            (prod) => prod.unique_cart_id !== unique_cart_id
          );
          if (productExistInCart) {
            this.localStorageService.shppingCartSession = productExistInCart;
          }
        })
      )
      .subscribe();
  }
  isCartExits() {
    let exits: boolean = false
    if (this.localStorageService.shppingCartSession != null && this.localStorageService.shppingCartSession != '') {
      exits = true;
    }
    return exits
  }
  removeCart(){
    this.itemsSubject.next([]);
    this.localStorageService.shppingCartSession=null;
    localStorage.removeItem('shppingCartSession')
  }
}
