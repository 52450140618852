import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public jwtHelper: JwtHelperService, private localStorageService: LocalStorageService, private sessionStorageService: SessionStorageService) { }

  fileBaseUrl: string = environment.services.files.baseUrl + environment.services.files.profileImage;

  public isAuthenticated(): boolean {
    const token = this.localStorageService.authToken;

    // Check whether the token is expired and return
    // true or false
    if (token) {
      return true
    }
    else {
      return false;
    }
  }


  public getUserDetails(): any {
    if (this.localStorageService.userDetails) {
      let loginUser = this.localStorageService.userDetails;
      // loginUser.avatar = this.fileBaseUrl + loginUser.id + '/' + loginUser.avatar
      return loginUser;
    } else {
      return {}
    }
  }

  public logout(): void {
    this.sessionStorageService.flushOnLogout();
    setTimeout(()=>{
      window.location.reload();
    },2)
  }

}
