import { Component,Inject } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';

import { PlatformLocation } from '@angular/common';
import { Params } from './shared/constants/params';
import { AppService } from './app.service';
import { SessionStorageService } from './shared/services/session-storage.service';
import { SharedVarService } from './shared/services/shared-var.service';
import {TranslateService} from '@ngx-translate/core';
import {LOCALE_ID} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  currentUrl: string;
  showLoadingIndicatior = false;
  loadingSetting: boolean = false;
  company_detail:any='';
  constructor(
    public _router: Router,
    location: PlatformLocation,
    public appServices: AppService,
    private sessionStorageService: SessionStorageService,
    private SharedVarService: SharedVarService,
    private translate: TranslateService,
    @Inject(LOCALE_ID) locale: string
  ) {
    translate.setDefaultLang(locale);
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicatior = true;
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.showLoadingIndicatior = false;
        this.getSettings();
      }
      window.scrollTo(0, 0);
    });
  }
  getSettings() {
    this.loadingSetting = true;
    this.appServices
      .getSettings()
      .pipe()
      .subscribe(
        (result: any) => {
          this.loadingSetting = false;
          if (result.status == 1) {
            if (Object.keys(result.company_detail).length > 0) {
              let companyData = {
                data: result.data,
                settings: result.company_detail,
              };
              this.company_detail=companyData.settings;
              $("#appFavicon").attr("href",this.company_detail.fav_icon.path);
            } else {
              this.sessionStorageService.setCompanyDetail(null);
            }
          } else {
            this.sessionStorageService.setCompanyDetail(null);
          }
        },
        (error: any) => {
          this.loadingSetting = false;
          console.log('err', error);
        }
      );
  }
  setSelectedBranch(brnachDt) {
    if (
      this.sessionStorageService.getCompanyDetail() &&
      brnachDt != null &&
      brnachDt != null
    ) {
      let companyData = this.sessionStorageService.getCompanyDetail();
      companyData[Params.selectedBrDt] = brnachDt;
      this.sessionStorageService.setCompanyDetail(companyData);
    }
  }
  getBranchDetails(branchId) {
    this.loadingSetting = true;
    let branchData = { branch_id: branchId };
    this.appServices
      .getBrancDetails(branchData)
      .pipe()
      .subscribe(
        (result: any) => {
          this.loadingSetting = false;
          if (result.status == 1) {
            if (Object.keys(result.data).length > 0) {
              this.setSelectedBranch(result.data);
            } else {
              this.sessionStorageService.setCompanyDetail(null);
            }
          } else {
            this.sessionStorageService.setCompanyDetail(null);
          }
        },
        (error: any) => {
          this.loadingSetting = false;
          console.log('err', error);
        }
      );
  }
}
