import { Component, OnInit, Input } from '@angular/core';

import { environment } from '../../environments/environment';
import { RouterService } from './../shared/services/router.service';
import { SessionStorageService } from './../shared/services/session-storage.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  fbLink: any;
  linkedInLink: any;
  helpSupport: any = '/' + environment.home.helpSupport;
  faqPage: any = '/' + environment.home.faq;
  aboutUs: any = '/' + environment.home.aboutUs;
  contactUs: any = '/' + environment.home.contactUs;
  ourServicesPath: any = '/' + environment.home.ourServices;
  privacyPolicyPath: any = '/' + environment.home.privacyPolicy;
  phoneNumber: any;
  email: any;
  logo: any;

  @Input('companyDetails') companyDetails: any = {
    name: 'Testing Company',
    phone: '656565565',
    email: 'testcompany@yopmail.com',
    address: 'This is testing company ',
    lang: { short_code: 'de', iso_code: 'de-DE' },
    fav_icon: { path: 'icon_path' },
    logo: { path: '../../assets/images/logo.png' },
  };
  constructor(
    private routService: RouterService,
    private translate: TranslateService,
    private sessionStorageService: SessionStorageService
  ) {
    setTimeout(() => {
      var d = new Date();
      var n = d.getFullYear();
      let copyRightsString = n + ' ' + this.companyDetails.name;
      this.translate
        .get('copyRightsTxt', {
          companydetails: copyRightsString,
        })
        .subscribe((res: string) => {
          this.copyRightsTxt = res;
        });
    }, 10);
  }
  goToPage(path) {
    this.routService.redirectToPath(path);
  }
  goToRedirectsericeInfo() {
    this.routService.redirectsericeInfo();
  }
  gotoContactUs() {
    this.routService.redirectTocontactus();
  }
  mailMe() {
    let emailText = 'mailto:' + this.companyDetails.email; // add the links to body
    window.location.href = emailText;
  }
  teleNoOpen() {
    let emailText = 'tel:' + this.companyDetails.phone; // add the links to body
    window.location.href = emailText;
  }
  copyRightsTxt: any = '';
  currentYear: any = '';
  ngOnInit(): void {
    var d = new Date();
    this.currentYear = d.getFullYear();
    /* if (this.sessionStorageService.getCompanyDetail() != null) {
      let companyDetails=this.sessionStorageService.getCompanyDetail()
      this.companyDetails=companyDetails["settings"];
    } */
  }
  redirectToBlog() {
    //this.routService.redirectToBlog()
  }
  redirectToReviews() {
    // this.routService.redirectToReviews()
  }
}
