<div *ngIf="showLoadingIndicatior" class="page-loader-wrapper">
  <app-page-loader></app-page-loader>
</div>
<div class="loader-old overlay" *ngIf="loadingSetting == true">
  <!--  <img style=" display: none;" id="loader" src="../../../assets/images/box.gif"> -->
  <img
    style="display: none"
    id="loader"
    src="../assets/images/api-loader.gif"
  />
</div>
<app-header [companyDetails]="company_detail"></app-header>
<!-- <app-sidebar *ngIf="!_router.url.includes('/authentication')"></app-sidebar> -->
<router-outlet ></router-outlet>

<app-footer [companyDetails]="company_detail"></app-footer>