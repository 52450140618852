export const environment = {
  production: false,
  services: {
  // gatewayUrl: 'http://butchershopapi.projectstatus.co.uk/manage/api/', //demo
  gatewayUrl: 'https://login.vorbestell-app.de/manage/api/', //lives
    siteUrl: 'http://localhost:4200/',
    //siteUrl: 'http://butchershop.24livehost.com/',
    googleApiKey: '',
    googleMapApi: {
      baseUrl: 'https://maps.googleapis.com/maps/api/',
      api: {
        directions: 'directions/json?origin=place_id:',
      },
    },
    files: {
      baseUrl: '',
      profileImage: '',
    },
    users: {
      baseUrl: '',
      api: {
        postLogin: 'login',
        profile: 'profile/:user_id',
        resetPassword: 'resetPassword', //update forgotten password
        postForgotPassword: 'forgotPassword',
        putUpdate: 'update_profile',
        register: 'signUp',
        changePassword: 'changePassword', //User Profile Password Update
        deliveryaddress:{
          baseUrl: 'deliveryaddress/',
          api: {
            list:'list',
            update:'update',
            create:'create',
            delete:'delete'
          }
        },
        orders:{
          baseUrl: 'order',
          api: {
            list:'s/list',
            view :'/view',
            update:"/update"
          }
        }
      },
    },
    offers: {
      baseUrl: 'offers',
      api: {
        list: '/list',
      }
    },
    cms: {
      baseUrl: '',
      api: {
        getPages: 'aboutUs',
        rearchUs:'ReachUs'
      },
    },
    master: {
      baseUrl: 'masters',
      api: {
        list: '/list',
      },
    },
    myAccount: {
      baseUrl: 'setting',
      api: {
        profile: '/profile/:id',
        update_profile: '/update',
        update_password: '/update_password',
      },
    },
    categroy: {
      baseUrl: 'menucategory',
      api: {
        list: '/list'
      },
    },
    products:{
      baseUrl: 'menuitem',
      api: {
        list: '/list'
      },
    },
    productSearch:{
      baseUrl: '',
      api: {
        searchItem:'searchItem'
      },
    },
    settings: {
      baseUrl: 'branch',
      api: {
        list: '/list',
        view: '/view',
      },
    },
    review: {
      baseUrl: 'review',
      api: {
        create :'/create'
      }
    },
    reviews: {
      baseUrl: 'reviews',
      api: {
        list: '/list',
      }
    },
    getDeliveryPrice : {
      baseUrl: '',
      api: {
        list: 'getDeliveryPrice',
        checkavailability:'checkavailability',
        businessHours:'businesshours'
      }
    }
  },
  home: {
    basePath: '',
    path: 'home',
    aboutUs: 'about-us',
    ourServices: 'our-services',
    contactUs: 'contact-us',
    privacyPolicy: 'privacy-policy',
    faq: 'faqs',
    helpSupport: 'help',
    blogs: 'blogs',
    servicesInfo:'services-info',
    reviews:'reviews'
  },
  profile: {
    basePath: 'my-account',
    dashboard:'dashboard',
    addresses:'my-addresses',
    editAccount: 'edit',
    viewAccount: 'view',
    changePassword: 'change-password',
    myOrders: 'orders',
    
    bookingDetails: 'my-bookings/booking-details:id'
  },
  authenticate: {
    basePath: 'authentication',
    changePassword: 'change-password',
    signin: 'sign-in',
    forgotPassword: 'forgot-password',
    signup: 'sign-up',
  },
  productCateogries: {
    basePath: 'menu',
    categories: 'categories/:branch_id',
    subCategories: 'items/:cat_id/:branch_id'
  },
  products: {
    basePath: 'items',
    listing: 'list/:branch_id/:main_cat_id/:cat_id',
    search: 'search/:branch_id/:keyword',
  },
  shopping:{
    basePath:'shopping',
    cart:'cart'
  },
  pageNotFoundPath: 'page-not-found',
  pageInternalServerError: '505',
  localeId:'',
/*   TOKENKEY:'vCQxKw7NyHjYKXI14xFP/jBk5FEevXK+fvxSOgLuZFk=',//Stagging
  TOKENIV: '/f1nwtzMyBTYtkCRfF3C24cKXfcgw6NHarMHZ7cihDs=', //Stagging */
  //  TOKENKEY:'QJoEObVUUsNkxz56aNWvL3qaUAYxyqt5WQG7NXjrLZk=',//dev demo
  //  TOKENIV: 'MP1hJmpx9AWDlC2MtXarLdyxTKpHk2Le4k1lIfHaJwk=', //Dev demo

 TOKENKEY:'KN5CaEZtA+sqJCk6Vh0tHTZ06TNKnnPVgpMe3CSa5vI=', //Live demo
  TOKENIV: 'VWJcXTF7JXBjQDsUhHf0lGHmW4sWPKInLMxr3FsvmPY=', //Live demo
  devicetype:'web',
  device_id:'123456',
  locales:{
    de: {
      code:'de-DE',
      currencyCode:'EUR'
    },
    en:{
      code:'en-US',
      currencyCode:'USD'
    }
  }
};
