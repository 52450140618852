<!--header-->
<header class="site-header">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <ng-container *ngIf="companyDetails">
        <a class="navbar-brand" href="javascript: void(0);"  (click)="goToPage(homePath)"
        ><img [src]="companyDetails.logo.path" alt="{{companyDetails.name}}"
      /></a>
      </ng-container>
      <button
        
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span></span><span></span><span></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a
              class="nav-link active"
              href="javascript: void(0);"
              aria-expanded="false"
              (click)="goToPage(homePath)" [class.active] ="currentUrl == homePath"
              >{{'Range'|translate}}</a
            >
          </li>
          
         
<!--           <li class="nav-item">
            <a (click)="goToRedirectsericeInfo()"  [class.active] ="currentUrl == servicesInfo"  class="nav-link" href="javascript: void(0);" aria-expanded="false" i18n="Services Information"
              >Services Information</a
            >
          </li> -->
          <ng-container *ngIf='authenticated'>
          <li class="nav-item">
            <a (click)="goToReviews()"  [class.active] ="currentUrl == reviewsPath"  class="nav-link" href="javascript: void(0);" aria-expanded="false" 
              >{{'Reviews'|translate}}</a
            >
          </li>
          </ng-container>
      <!--  <li class="nav-item">
            <a class="nav-link" href="javascript: void(0);" aria-expanded="false" i18n="Help"
              >Help</a
            >
          </li> -->
          <li class="nav-item">
            <a (click)="gotoContactUs()" class="nav-link" href="javascript: void(0);" aria-expanded="false" i18n="Contact"
              >Contact us</a
            >
          </li>
          <li class="nav-item">
            <a (click)="goToPage(aboutusPath)"  [class.active] ="currentUrl == aboutusPath"  class="nav-link" href="javascript: void(0);" aria-expanded="false" i18n="About Us"
              >About Us</a
            >
          </li>
          <li class="nav-item">
            <a (click)="goToRedirectsericeInfo()"  [class.active] ="currentUrl == servicesInfo"  class="nav-link" href="javascript: void(0);" aria-expanded="false"
              >{{ "Services Information" | translate }}</a
            >
          </li>
        </ul>
      </div>
      <div class="head-right">
        <ng-container *ngIf='!authenticated'>
          <a  href="javascript:void(0);" (click)="goToSingIn()" class="btn-custom small" i18n="Login">Login</a>
        <a href="javascript:void(0);" (click)="goToSingUp()"  class="btn-custom small black" i18n="Register">Register</a>
        </ng-container>
        <ng-container *ngIf='authenticated'>
          <a  href="javascript:void(0);" (click)="logout()" class="btn-custom small" i18n="Logout">Logout</a>
        <a href="javascript:void(0);" (click)="goToMyAccount()"  class="btn-custom small black" i18n="My Profile">My Profile</a>
        </ng-container>
        <a href="javascript: void(0);" class="cart-btn" (click)="redirectCart()">
          <img src="../../../assets/images/cart-icon.png" alt="cart" i18n-alt />
          <em i18n="0">{{(items$ | async)?.length}}</em>
        </a>
      </div>
    </div>
  </nav>
</header>
<!--header-->
