export class ApiResponseCode {
/* 	static Success = 200;
	static Error = 400;
	static NOTFOUND = 404;
	static UNAUTHORIZED = 401;
	static ServerError = 500; */
	static Success = 1;
	static Error = 400;
	static NOTFOUND = 404;
	static UNAUTHORIZED = 401;
	static ServerError = 500;

}



