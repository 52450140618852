import { Component, OnInit,Input } from '@angular/core';
import { RouterService } from '../shared/services/router.service';
import { AuthService } from '../shared/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from '../../environments/environment';
/* import { Params } from '../shared/constants/params';
import * as jQuery from 'jQuery'; */
import { SessionStorageService } from '../shared/services/session-storage.service';
import { SharedVarService } from '../shared/services/shared-var.service';
import { CartService } from 'src/app/shared/services/cart.service';
// export for others scripts to use

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input('companyDetails') companyDetails: any={
    name: 'Testing Company',
    phone: '656565565',
    email: 'testcompany@yopmail.com',
    address: 'This is testing company ',
    lang: { short_code: 'de', iso_code: 'de-DE' },
    fav_icon: { path: 'icon_path' },
    logo: { path: "../../assets/images/logo.png" },
  }; 
  authenticated: any;
  currentUrl: any;
  logo: any;
  fbLink: any;
  linkedInLink: any;
  phoneNumber: any;
  email: any;
  isCheckoutSessionExists: boolean = false;
  constructor(
    private sessionStorageService: SessionStorageService,
    private _authService: AuthService,
    private routService: RouterService,
    private router: Router,
    private sharedVarService:SharedVarService,
    private cartService:CartService
  ) {
    
  }
  items$ = this.cartService.items$;
  homePath: any = '/' + environment.home.path;
  aboutusPath: any = '/' + environment.home.aboutUs;
  ourServices: any = '/' + environment.home.ourServices;
  contactUs: any = '/' + environment.home.contactUs;
  servicesInfo:any="/"+environment.home.servicesInfo;
  reviewsPath:any="/"+environment.home.reviews;
  redirectCart(){
    this.routService.redirectCart()
  }
  ngOnInit(): void {
    if (this.sessionStorageService.getCompanyDetail() != null) {
    /*   this.sharedVarService.getValue()
      .subscribe(
        (result: any) => {
          this.companyDetails=result['settings'];
        
        },
        (error: any) => {
          console.log('err', error);
        }
      ); */
    }
    this.loadingSetting = false;
    this.authenticated = this._authService.isAuthenticated();
    this.getCheckoutSession();
    this.router.events.subscribe((result: any) => {
      if (result instanceof NavigationEnd) {
        this.currentUrl = window.location.pathname;
      }
    });

    //this.setHeaderLogo();
  }
  goToRedirectsericeInfo(){
    this.routService.redirectsericeInfo()
  }
  goToSingUp() {
    this.routService.redirectSignup();
  }
  goToSingIn() {
    this.routService.redirectSignIn();
  }
  logout() {
    this._authService.logout();
  }
  goToMyAccount() {
    this.routService.redirectMyAccount();
  }
  goToPage(path) {
    this.routService.redirectToPath(path);
  }
  gotoContactUs(){
    this.routService.redirectTocontactus()
  }
  goToReviews(){
    this.routService.redirectReviewsPath()
  }
  loadingSetting: boolean = false;
  /* setHeaderLogo() {
    this.loadingSetting = true;
    this.layoutService
      .getSettings()
      .pipe()
      .subscribe(
        (result: any) => {
          this.loadingSetting = false;
          if (result.message == 'success') {
            if (result.body && result.body.length) {
              result.body.forEach((setting) => {
                if (setting.name === 'Frontend Logo') {
                  // this.logo = environment.services.endpoint + '/public/uploads/' + setting.value;
                } else if (setting.name === 'Facebook') {
                  this.fbLink = setting.value;
                } else if (setting.name === 'Linked In') {
                  this.linkedInLink = setting.value;
                } else if (setting.name === 'Phone Number') {
                  this.phoneNumber = setting.value;
                } else if (setting.name === 'from email id') {
                  this.email = setting.value;
                }
              });
            }
          }
        },
        (error: any) => {
          this.loadingSetting = false;
          console.log('err', error);
        }
      );
  } */
  getCheckoutSession() {
    /*  if(localStorage.getItem(Params.getQuoateSessName)){
         this.isCheckoutSessionExists=true;
     }else{
       this.isCheckoutSessionExists=false;
     } */
  }
  redirectToCheckOutPage() {
    //this.routService.redirectToCheckOutPage();
  }
}
