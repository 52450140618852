import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './page404/page404.component';
import { Page500Component } from './page500/page500.component';
import { environment } from '../environments/environment';

let homeBasePath = environment.home.basePath;
let profilePath = environment.profile.basePath;
let categoriesPath = environment.productCateogries.basePath
let authenticatePath = environment.authenticate.basePath;
let pageNotFound = environment.pageNotFoundPath;
let pageInternalServerError = environment.pageInternalServerError;
let productBasePath=environment.products.basePath;
let shoppingBasePath=environment.shopping.basePath;
import { AuthGuard } from './shared/guards/auth-guard.service';

const routes: Routes = [
  {
    path: homeBasePath,
    loadChildren: () =>
      import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: productBasePath,
    loadChildren: () =>
      import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    path: categoriesPath,
    loadChildren: () =>
      import('./product-categories/product-categories.module').then(m => m.ProductCategoriesModule)
  },
  {
    path: shoppingBasePath,
    loadChildren: () =>
      import('./shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule)
  },
  {
    path: profilePath,
    loadChildren: () =>
      import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: authenticatePath,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        m => m.AuthenticationModule
      ),
  },
  {
    path: pageNotFound,
    component: Page404Component
  },
  {
    path: pageInternalServerError,
    component: Page500Component
  },
  {
    path: '',
    redirectTo: homeBasePath,
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/' + pageNotFound }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
