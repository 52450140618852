<div class="my-account-nav-verticle flex-shrink-1">
  <h3 class="side-tab-title" i18n>My Profile</h3>
  <a
    class="nav-link"
    [class.active]="currentUrl === '/my-account/dashboard'"
    (click)="redirectToMyAccountDashboard()"
    ><img src="../../../assets/images/dashboard-icon.png" alt="" /><ng-container
      i18n
      >Dashboard</ng-container
    ></a
  >
  <a
    class="nav-link"
    [class.active]="currentUrl === '/my-account/view'"
    (click)="redirectViewProfile()"
    ><img
      src="../../../assets/images/view-profile-icon.png"
      alt=""
    /><ng-container i18n>View Profile</ng-container></a
  >
  <a
    class="nav-link"
    [class.active]="currentUrl === '/my-account/edit'"
    (click)="redirectEditProfile()"
  >
    <img
      src="../../../assets/images/edit-profile-icon.png"
      alt=""
    /><ng-container i18n>Edit Profile</ng-container>
  </a>
  <a
    class="nav-link"
    [class.active]="currentUrl === '/my-account/change-password'"
    (click)="redirectChangePassword()"
    ><img
      src="../../../assets/images/change-pass-icon.png"
      alt=""
    /><ng-container i18n>Change Password</ng-container></a
  >
  <a
    class="nav-link"
    [class.active]="currentUrl === '/my-account/my-addresses'"
    (click)="redirectToMyAddresses()"
    ><img
      src="../../../assets/images/my-address-icon.png"
      alt=""
    />{{'Delivery Address'|translate}}</a
  >
  <a
    class="nav-link"
    (click)="goToMyOrders()"
    [class.active]="currentUrl === '/my-account/orders'"
    ><img src="../../../assets/images/my-order-icon.png" alt="" /><ng-container
      i18n
      >My Order</ng-container
    ></a
  >
</div>
