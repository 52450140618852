import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedVarService {
     initValue={};
    private routerInfo: BehaviorSubject<any>;

    constructor() {
        this.routerInfo = new BehaviorSubject<any>(this.initValue);
    }

    getValue(): Observable<any> {
        return this.routerInfo.asObservable();
    }
    setValue(newValue): void {
        this.routerInfo.next(newValue);
    }

}
