import { Observable, BehaviorSubject } from 'node_modules/rxjs';
import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import { RouterService } from '../services/router.service';
@Injectable()
export class SessionStorageService {
  private userAuthDetailsBehaviorSubject = new BehaviorSubject(null);
  private userDetailsBehaviorSubject = new BehaviorSubject(null);
  private userRegistrationBehaviorSubject = new BehaviorSubject(null);
  private companyDetailBehaviorSubject = new BehaviorSubject(null);
  constructor(private localStorageService: LocalStorageService, private routerService: RouterService) {}

  public flushOnLogout(): void {
    this.setUserAuthDetails('');
  }

  // User Auth Details
  get userAuthDetails() {
    return this.userAuthDetailsBehaviorSubject.asObservable();
  }
  get companyDetail() {
    return this.companyDetailBehaviorSubject.asObservable();
  }
  public setUserAuthDetails(value) {
    if (value === null ||value === '') {
      this.localStorageService.authToken = '';
      this.localStorageService.userDetails = null;
      this.routerService.redirectSignIn();
    } else {
      this.localStorageService.authToken = value.token;
      this.localStorageService.userDetails=value
    }
    this.userAuthDetailsBehaviorSubject.next(value);
  }
  public getCompanyDetail(): any {
    let companyDetail=null;
    if (this.localStorageService.companyDetail) {
      companyDetail=this.localStorageService.companyDetail;
    } 
    return companyDetail
  }
  public setCompanyDetail(value) {
    if (value === null) {
      this.localStorageService.companyDetail = '';
    } else {
      this.localStorageService.companyDetail = value
    }
    this.companyDetailBehaviorSubject.next(value);
  }
  // User Details
  get userDetails() {
    return this.userDetailsBehaviorSubject.asObservable();
  }

  public setUserDetails(value) {
    this.localStorageService.userDetails = JSON.stringify(value);
    this.userDetailsBehaviorSubject.next(value);
  }

  // user
  get userRegistration() {
    return this.userRegistrationBehaviorSubject.asObservable();
  }
  public setUserRegistration(value) {
    this.userRegistrationBehaviorSubject.next(value);
  }
}
