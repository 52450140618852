
import { Component, OnInit, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './../../shared/services/common.service';
import { RouterService } from 'src/app/shared/services/router.service';
import { LOCALE_ID } from '@angular/core';
@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.css']
})
export class ConfirmAlertComponent implements OnInit {
  @Input() data;
  localDetails: any = ''
  settings: any = '';
  productName: any = '';
  showAttrbScrollbar: boolean = false;
  okClick:boolean=true;
  cancelClick:boolean=false;
  constructor(
    public routerService: RouterService,
    public commonService: CommonService,
    public activeModal: NgbActiveModal,
    @Inject(LOCALE_ID) locale: string
  ) { this.localDetails = this.commonService.localDetails(locale); }
  ngOnInit(): void {
  }
  close(text){
    this.activeModal.close(text)
   }
}

