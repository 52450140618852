import { Component, OnInit } from '@angular/core';
import { RouterService } from './../shared/services/router.service';
import { environment } from "../../environments/environment";
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
})
export class Page404Component implements OnInit {
  homePath:any="/"+environment.home.path
  constructor(private routerService: RouterService) {}
  ngOnInit() {}
  goToPage(path){
    this.routerService.redirectToPath(path)
  }
}
