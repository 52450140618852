import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from './layout/layout.module';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader.service';
import { Page500Component } from './page500/page500.component';
import { Page404Component } from './page404/page404.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, HttpClientModule,HttpClient } from '@angular/common/http';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthService } from './shared/services/auth.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { CommonService } from './shared/services/common.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { DateParserFormaterService } from './shared/services/date-parser-formater.service';
import { HttpService } from './shared/services/http.service';
import { SessionStorageService } from './shared/services/session-storage.service';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { AppService } from '../app/app.service';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CartService } from './shared/services/cart.service';
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http,'../assets/locale/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('accessToken');
}
const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: tokenGetter,
  },
};
@NgModule({
  declarations: [
    AppComponent,
    Page500Component, 
    Page404Component,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
 
  SharedModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    ToastrModule.forRoot(), 
    JwtModule.forRoot(JWT_Module_Options),
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    LayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      defaultLanguage: 'en'
  })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    DynamicScriptLoaderService,
    AuthService,
    ErrorHandlerService,
    DateParserFormaterService,
    CommonService,
    AuthGuard,
    HttpService,
    SessionStorageService,
    LocalStorageService,
    AppService,
    CartService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
