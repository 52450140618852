import { OwlDateTimeIntl } from 'ng-pick-datetime';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class DefaultIntl extends OwlDateTimeIntl {
 
  cancelBtnLabel ='Abbrechen'
  setBtnLabel = 'Ok'
};