
import { Injectable } from '../../../../node_modules/@angular/core';
import {Params} from '../constants/params'
//import { IUser } from '../models/user';
export class LocalStorageService {
	private readonly _authToken: any = Params.accessToken;
	private readonly _userDetails: string = Params.user_details;
	private readonly _companyDetails: string = Params.company_detail_session;
	private readonly _shppingCartSession:string=Params.shppingCartSession
	constructor() { }

	get authToken(): string {
		if (localStorage.getItem(this._authToken) !== '') {
			let authToken= this._authToken as any
			return localStorage.getItem(authToken) as any;
		}
		return '';
	}

	get shppingCartSession(): any {
		if (localStorage.getItem(this._shppingCartSession) !== '' && localStorage.getItem(this._shppingCartSession) != null) {
			let cartSessioin:any=localStorage.getItem(this._shppingCartSession);
			//return JSON.parse(atob(cartSessioin))
			return JSON.parse(cartSessioin)
		}
		return '';
	}
	set shppingCartSession(value: any) {
		if (value !== null && value !== '' && value !== undefined) {
			//localStorage.setItem(this._shppingCartSession, btoa(JSON.stringify((value))));
			
			localStorage.setItem(this._shppingCartSession, JSON.stringify(value));
		}	
		else {
			if (localStorage.getItem(this._shppingCartSession) !== '') {
				localStorage.removeItem(this._shppingCartSession);
			}
		}
	}
	set authToken(value: string) {
		if (value !== null && value !== '' && value !== undefined) {
			localStorage.setItem(this._authToken, value);
		}
		else {
			if (localStorage.getItem(this._authToken) !== '') {
				localStorage.removeItem(this._authToken);
			}
		}
	}

	get userDetails() {
		if (this.authToken !== '') {
			if (localStorage.getItem(this._userDetails)) {
				let userDetails=''
				userDetails= localStorage.getItem(this._userDetails) as any;
				return JSON.parse(atob(userDetails));
			}
			return null;
		}
		return null;
		
	}
	set userDetails(value: any) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._userDetails, btoa(JSON.stringify((value))));
		}
		else {
			if (localStorage.getItem(this._userDetails) !== null) {
				localStorage.removeItem(this._userDetails);
			}
		}
	}
	set companyDetail(value: any){
		if (value !== null && value !== '' && value !== undefined) {
			localStorage.setItem(this._companyDetails, JSON.stringify(value));
		}
		else {
			if (localStorage.getItem(this._companyDetails) !== '') {
				localStorage.removeItem(this._companyDetails);
			}
		}
	}
	get companyDetail() {
		if (localStorage.getItem(this._companyDetails) !== '') {
			let details=localStorage.getItem(this._companyDetails) as any;
			return  JSON.parse(details);
		}
		return '';
	}
}
