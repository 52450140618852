

<div class="about-us">
  <div class="container">
      <div class="about-inner">
          <div class="payment-success">
              <figure><img src="../../../assets/images/warning-28-xxl.png" alt="" height="255" width="255"></figure>
              <h2>404 Not Found !!</h2>
              <div class="about-text">
                <p> Looks Like You're Lost.</p>
                <p>The Page You Are Looking For Not Available!</p>
              </div>

          </div>
          <div class="home-btn" style="margin-bottom: 15px;">
              <button (click)='goToPage(homePath)' title="Go to home page" type="submit"
                  class="btn btn-custom black-btn btn-block">
                  Home</button>
          </div>

      </div>
  </div>
</div>