import { Injectable } from "@angular/core";
import { ApiResponseCode } from "../constants/api-response-code";
import { RouterService } from "./router.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../shared/services/auth.service";
import { AlertService } from '../../shared/services/alert.service';

@Injectable({
  providedIn: "root"
})
export class ErrorHandlerService {
  constructor(
    private routerService: RouterService,
    private toastrService: ToastrService,
    public authService: AuthService,
    private alertService: AlertService
  ) { }

  public handleError(error: any) {
    //let displayLogoutNotification = true;
    if (error.status == ApiResponseCode.UNAUTHORIZED) {
      let errors = error.error;
      this.alertService.popup('error', errors["message"]);
      this.authService.logout();
      this.routerService.redirectSignIn();
      return false;
    } else if (error.status == ApiResponseCode.Error) {
      let errors = error.error;
      this.alertService.popup('error', errors["message"]);
      return false;
        } else if (error.status == ApiResponseCode.Success) {
      let errors = error.error;
      this.alertService.popup('error', errors["message"]);
      return false;
    } else if (error.status == ApiResponseCode.ServerError) {
      let errors = error.error;
      this.alertService.popup('error', errors["message"]);
      return false;
    } else if (error.status == ApiResponseCode.NOTFOUND) {
      let errors = error.error;
      this.alertService.popup('error', errors["message"]);
      return false;
    } else {
      this.toastrService.error(
        "Something went wrong. Please contact administrator."
      );
      return false;
    }
  }
  public GSTINError(message) {
    this.toastrService.error(message);
  }
  closeLogoutNotification() { }

  public defaultError() {
    this.toastrService.error("Something went wrong. Please try again");
  }
  public successMessage(message: any) {
    this.toastrService.success(message, "", { timeOut: 3000 });
  }

  public errorMessage(message: any, timeOut: any = 50000) {
    this.toastrService.error(message, "", { timeOut: timeOut });
  }
  public errorMessageNoDataFound() {
    this.toastrService.info("No data found. Please try again");
  }
}
