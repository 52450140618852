import { Router, NavigationExtras } from "@angular/router";
import { CommonService } from "./common.service";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as $ from 'jQuery' 
import { HomeServicesService } from 'src/app/home/home-services.service';
let env = environment;
let home = env.home
let authenticate = env.authenticate
let myAccount = env.profile
let cateogries = env.productCateogries
let homeRedirectPath = '/' + home.path
let singUpPath = authenticate.signup;
let singInpath = authenticate.signin;
let forgotPasswordPath = authenticate.forgotPassword;
let changePasswordPath = authenticate.changePassword;
let myAccountViewPath = myAccount.viewAccount;
let myAccountDashboardPath = myAccount.dashboard;
let myAccountAddressesPath = myAccount.addresses;
let myAccountEditPath = myAccount.editAccount;
let categoriesPath = cateogries.categories;
let subCategoriesPath = cateogries.subCategories;

let shopping = env.shopping;
let cartPath =shopping.cart;
let cartRedirectPath="/" + shopping.basePath + "/" + cartPath;

let myOrdersPath = myAccount.myOrders
let myAccountChangePassPath = myAccount.changePassword;
let singUpRedirectPath = "/" + authenticate.basePath + "/" + singUpPath;
let singInRedirectPath = "/" + authenticate.basePath + "/" + singInpath
let forgotPassRedirectPath = "/" + authenticate.basePath + "/" + forgotPasswordPath
let changePasswordPathRedirectPath = "/" + authenticate.basePath + "/" + changePasswordPath
let accountViewRedirectPath = "/" + myAccount.basePath + "/" + myAccountViewPath;
let accountDashboardRedirectPath = "/" + myAccount.basePath + "/" + myAccountDashboardPath;
let accountAddressesRedirectPath = "/" + myAccount.basePath + "/" + myAccountAddressesPath;
let accountEditRedirectPath = "/" + myAccount.basePath + "/" + myAccountEditPath;
let categoryRedirectPath = "/" + cateogries.basePath + "/" + categoriesPath;
let subCategoriesRedirectPath = "/" + cateogries.basePath + "/" + subCategoriesPath;
let myOrdersRedirectPath = "/" + myAccount.basePath + "/" + myOrdersPath;
let accountChangePassRedirectPath = "/" + myAccount.basePath + "/" + myAccountChangePassPath;
let aboutUsPath = "/" + home.aboutUs
let products=environment.products;
let productListingRedirectPath="/"+products.basePath+"/"+environment.products.listing;
let productSearchPath="/"+products.basePath+"/"+environment.products.search;
let contactUsPath="/" + home.contactUs;
let serviceInfoPath="/" + home.servicesInfo;
let reviewsPath="/"+home.reviews
@Injectable({
    providedIn: "root"
})
export class RouterService {
    private readonly extras: NavigationExtras = { skipLocationChange: true };
    constructor(
        private router: Router,
        private commonService: CommonService,
        private _homeSerivces:HomeServicesService
    ) { }
    public redirectHome() {
        this.hideSideMenuBar();
        this.router.navigate([homeRedirectPath], { skipLocationChange: false });
    }
    public redirectSignup() {
        this.hideSideMenuBar();
        this.router.navigate([singUpRedirectPath], { skipLocationChange: false });
    }
    public redirectCart() {
        this.hideSideMenuBar();
        this.router.navigate([cartRedirectPath], { skipLocationChange: false });
    }
    
    public redirectSignIn() {
        this.hideSideMenuBar();
        this.router.navigate([singInRedirectPath], { skipLocationChange: false });
    }
    public redirectForgotPassword() {
        this.hideSideMenuBar();
        this.router.navigate([forgotPassRedirectPath], { skipLocationChange: false });
    }
    public redirectToChangePassword() {
        this.hideSideMenuBar();
        this.router.navigate([changePasswordPathRedirectPath], { skipLocationChange: false });
    }
    public redirectMyAccount() {
        this.hideSideMenuBar();
        this.router.navigate([accountViewRedirectPath], { skipLocationChange: false });
    }
    public redirectToMyAddresses(){
        this.hideSideMenuBar();
        this.router.navigate([accountAddressesRedirectPath], { skipLocationChange: false });
    }
    public redirectToMyAccountDashboard() {
        this.hideSideMenuBar();
        this.router.navigate([accountDashboardRedirectPath], { skipLocationChange: false });
    }
    public redirectMyAccountEdit() {
        this.hideSideMenuBar();
        this.router.navigate([accountEditRedirectPath], { skipLocationChange: false });
    }
    public redirectToCategory(branch_id) {
        this.hideSideMenuBar();
        let path=categoryRedirectPath.replace(':branch_id',branch_id)
        this.router.navigate([path],  { skipLocationChange: false });
    }
    public redirectToProducList(cat_id,branch_id,main_cat_id) {
        this.hideSideMenuBar();
        let path=productListingRedirectPath.replace(':branch_id',branch_id).replace(':cat_id',cat_id).replace(':main_cat_id',main_cat_id)
        this.router.navigate([path],  { skipLocationChange: false });
    }
    public redirectToProducSearch(keyword,branch_id) {
        this.hideSideMenuBar();
        let path=productSearchPath.replace(':branch_id',branch_id).replace(':keyword',keyword)
        this.router.navigate([path],  { skipLocationChange: false });
    }
    public redirectToSubCategory(cat_id,branch_id) {
        this.hideSideMenuBar();
        let path=subCategoriesRedirectPath.replace(':cat_id',cat_id).replace(':branch_id', branch_id)
        this.router.navigate([path],  { skipLocationChange: false });
    }
    public redirectTomyOrders() {
        this.hideSideMenuBar();
      
        this.router.navigate([myOrdersRedirectPath], { skipLocationChange: false });
    }
    public redirectChangePassword() {
        this.hideSideMenuBar();
        this.router.navigate([accountChangePassRedirectPath], { skipLocationChange: false });
    }
    public redirectToPath(path) {
        this.hideSideMenuBar();
        this.router.navigate([path], { skipLocationChange: false })
    }
    public redirectToAboutUs() {
        this.hideSideMenuBar();
        this.router.navigate([aboutUsPath], { skipLocationChange: false })
    }
    public redirectTocontactus() {
        this.hideSideMenuBar();
        this.router.navigate([contactUsPath], { skipLocationChange: false })
    }
    public redirectsericeInfo() {
        this.hideSideMenuBar();
        this.router.navigate([serviceInfoPath], { skipLocationChange: false })
    }
    public redirectReviewsPath() {
        this.hideSideMenuBar();
        this.router.navigate([reviewsPath], { skipLocationChange: false })
    }
    
    private hideSideMenuBar() {
        $('.iphonNav').removeClass('nav-open');
        $('body').removeClass('scroll-hidden');
        $(".navbar-toggler").removeClass("collapsed");
        $(".navbar-toggler").removeClass("collapsed");
        $(".navbar-collapse").removeClass("show");
       /*  $('.navbar-nav>li>a').on('click', function(){
            $('.navbar-collapse').collapse('hide');
        }); */
    }

}