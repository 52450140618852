import { Injectable } from '@angular/core';
import { DynamicScriptLoaderService } from '../shared/services/dynamic-script-loader.service';
import { HttpService } from "../shared/services/http.service";
import { Observable } from "rxjs";
import { Response } from "../shared/models/response";
import { CommonService } from "../shared/services/common.service";
import { environment } from './../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HomeServicesService {
  private readonly apiCmsBaseUrl =
    environment.services.gatewayUrl + environment.services.cms.baseUrl;
    private readonly apiReviewBaseUrl =
    environment.services.gatewayUrl + environment.services.review.baseUrl;
    private readonly apiReviewsBaseUrl =
    environment.services.gatewayUrl + environment.services.reviews.baseUrl;
  constructor(private scriptLoader: DynamicScriptLoaderService, private httpService: HttpService,
    private common: CommonService) {

  }
  async startScript(scriptName) {
    return await this.scriptLoader.load(scriptName).then(data => {
    }).catch(error => console.log(error));
  }
  public postReviews(data): Observable<Response> {
   let url = environment.services.review.api.create;
    return this.httpService.post<Response>(this.apiReviewBaseUrl, url, data);
  }
  public getCmsPages(data): Observable<Response> {
    const url = environment.services.cms.api.getPages;
    return this.httpService.post<Response>(this.apiCmsBaseUrl, url, data);
  }
  public getReviwes(data): Observable<Response> {
    const url = environment.services.reviews.api.list
    return this.httpService.post<Response>(this.apiReviewsBaseUrl, url, data);
  }
  public submitContactEnquery(data): Observable<Response> {
    const url = environment.services.cms.api.rearchUs;
    return this.httpService.post<Response>(this.apiCmsBaseUrl, url, data);
  }
  makeFormTouched(formData) {
    return this.common.markFormGroupTouched(formData);
  }
}
