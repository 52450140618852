import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelect2Module } from 'ng-select2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BtnLoaderComponent } from './components/btn-loader/btn-loader.component';
import { TwoDigitDecimaNumberDirective } from './services/two-digit-decima-number.directive';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule,OwlDateTimeIntl} from 'ng-pick-datetime';
import { MomentModule } from 'ngx-moment';
import { BarRatingModule } from "ngx-bar-rating";
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http,'../assets/', '.json');
}
import {
  searchCatNamePipe,
  searchProductName,
  CurrencySymbolPipe,
  filterOrders
} from './services/pipes';
import { ConfirmAlertComponent } from './confirm-alert/confirm-alert.component';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { DefaultIntl } from './services/datetime-picker-label-custom.class';
@NgModule({
  declarations: [
    BtnLoaderComponent,
    TwoDigitDecimaNumberDirective,
    searchCatNamePipe,
    searchProductName,
    CurrencySymbolPipe,
    filterOrders,
    ConfirmAlertComponent,
    OnlyNumbersDirective,
    
  ],
  imports: [

  OwlDateTimeModule,
    BarRatingModule,
    MomentModule, 
         OwlNativeDateTimeModule,
  MaterialModule,
  TranslateModule.forChild({
    extend: true
}),
    CommonModule,
    NgSelect2Module,
    FormsModule,
    ReactiveFormsModule,
  ],
  entryComponents:[ConfirmAlertComponent],
   /*  providers: [CartService], */
  exports: [
    CommonModule,
    BtnLoaderComponent,
    OnlyNumbersDirective,
    TwoDigitDecimaNumberDirective,
    searchCatNamePipe,
    searchProductName,
    CurrencySymbolPipe,
    TranslateModule,
    filterOrders,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    MomentModule,
    BarRatingModule
  ],
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
]
})
export class SharedModule {}
