import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpService } from "./shared/services/http.service";
import { Observable } from "rxjs";
import { Response } from "./shared/models/response";
import { environment } from '../environments/environment';

@Injectable()
export class AppService {
  private readonly apiUsersBaseUrl =
  environment.services.gatewayUrl + environment.services.settings.baseUrl;
    constructor(@Inject(DOCUMENT) private _document: HTMLDocument,private httpService: HttpService,){

    } 
    public getSettings(): Observable<Response> {
      const url = environment.services.settings.api.list;
      return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
    }
    public getBrancDetails(data): Observable<Response> {
      const url = environment.services.settings.api.view;
      return this.httpService.post<Response>(this.apiUsersBaseUrl, url,data);
    }
    setAppFavicon(id: string, basepath: string, icon: string){
       this._document.getElementById('appFavicon')?.setAttribute('href', basepath+"/"+ icon);
    }
}