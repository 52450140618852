<!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-title"  i18n="Confirm">Confirm</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="close(cancelClick)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>{{data}}</strong></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close(cancelClick)">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="close(okClick)">Ok</button>
  </div> -->

  <div class="modal-custom-popup">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <ng-container i18n="Confirm">Confirm</ng-container>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="close(cancelClick)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-custom-inner">
            <div class="transaction-detail">
              <div class="row">
                <div class="col-sm-12">
                  <div class="d-flex justify-content-center">
                    <p i18n="Are you sure you want to delete address?">
                      {{data}}
                    </p>
                  </div>

                  <div class="avail-form-group">
                    <button
                      class="btn-custom small delete-address-btn"
                      type="submit"
                      (click)="close(okClick)"
                    >
                      <ng-container i18n>Ok</ng-container>
                    </button>
                    &nbsp;
                    <button
                      class="btn-custom small delete-address-btn"
                      (click)="close(cancelClick)"
                      type="submit"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <ng-container i18n>Cancel</ng-container>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>