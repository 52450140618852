import { Pipe, PipeTransform } from "@angular/core";
import { getCurrencySymbol } from '@angular/common';
@Pipe({ name: "removeUnderscore" })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/_/g, " ");
  }
}

@Pipe({ name: "truncate" })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = true, ellipsis = "...") {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(" ");
    }
    return `${value.substr(0, limit)}${ellipsis}`;
  }
}
@Pipe({ name: "filter" })
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
    return items.filter((it) => {
      return it["title"].toLocaleLowerCase().includes(searchText) || it["short_description"].toLocaleLowerCase().includes(searchText) || it["org_name"].toLocaleLowerCase().includes(searchText);
    });
  }
}
@Pipe({ name: "filterDiscussion" })
export class filterDiscussionPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
    return items.filter((it) => {
      return it["type"].toLocaleLowerCase().includes(searchText) || it["title"].toLocaleLowerCase().includes(searchText) || it["name"].toLocaleLowerCase().includes(searchText);
    });
  }
}
@Pipe({ name: "searchCatName" })
export class searchCatNamePipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
    let filteredArr: any = items.filter((it) => {
      let searched = it["name"].toLocaleLowerCase().includes(searchText);
      return searched;
    });
    let filteredArrLenght = filteredArr.length;
    if (filteredArrLenght == 0) {
      filteredArr=[
        {
          "id": "0",
        }
      ]
    }

    return filteredArr;
  }
}
@Pipe({ name: "searchProductName" })
export class searchProductName implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
    return items.filter((it) => {
      return it["name"].toLocaleLowerCase().includes(searchText) || it["description"].toLocaleLowerCase().includes(searchText);
    });
  }
}
@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {

  transform(
    code: string,
    format: 'wide' | 'narrow' = 'narrow',
    locale?: string
  ): any {
    return getCurrencySymbol(code, format, locale);
  }
}

@Pipe({ name: "filterOrders" })
export class filterOrders implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
    let filteredArr: any = items.filter((it) => {
      return it["order_status"].toLocaleLowerCase().includes(searchText) 
    });
    let filteredArrLenght = filteredArr.length;
    if (filteredArrLenght == 0) {
      filteredArr=[
        {
          "id": "-1",
        }
      ]
    }

    return filteredArr;
  }
}

