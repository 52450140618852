import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private  notify:ToastrService) { }
    popup(type, message,customtimeout:number=3000) { 
      if(type === 'success') {
        this.notify.success('', message, {
          timeOut: customtimeout,
        });
      } else {
        this.notify.error('', message, {
          timeOut: customtimeout,
        });
      }
    
    }
}
