import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  declarations: [SidebarComponent, PageLoaderComponent],
  imports: [
    CommonModule,
    MatProgressBarModule,
    SharedModule
  ],
  exports: [SidebarComponent, CommonModule, PageLoaderComponent, MatProgressBarModule]

})
export class LayoutModule { }
